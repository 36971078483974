var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(!_vm.isDataLoaded)?_c('b-card',[_c('div',{staticClass:"text-center text-success my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]):_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('b-row',{staticClass:"align-items-end"},[(_vm.usages.length)?_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"usage_id","name":_vm.$t('usage_id'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('usage_id')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.direction,"label":"name","options":_vm.usages,"reduce":function (item) { return item.id; },"placeholder":_vm.$t('select')},on:{"input":function($event){return _vm.inputChange('usage_id')}},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('No options'))+" ")]},proxy:true}],null,true),model:{value:(_vm.form.usage_id),callback:function ($$v) {_vm.$set(_vm.form, "usage_id", $$v)},expression:"form.usage_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,2067255670)})],1):_vm._e(),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"the_sub_type_of_ad","name":_vm.$t('the_sub_type_of_ad'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('the_sub_type_of_ad')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.direction,"label":"name","options":_vm.the_sub_type_of_ads,"reduce":function (item) { return item.id; },"placeholder":_vm.$t('select')},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('No options'))+" ")]},proxy:true}],null,true),model:{value:(_vm.form.the_sub_type_of_ad),callback:function ($$v) {_vm.$set(_vm.form, "the_sub_type_of_ad", $$v)},expression:"form.the_sub_type_of_ad"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),(_vm.types.length)?_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"type_id","name":_vm.$t('type_id'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('type_id')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.direction,"label":"name","options":_vm.types,"reduce":function (item) { return item.id; },"placeholder":_vm.$t('select')},on:{"input":function($event){return _vm.inputChange('sub_type_id')}},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('No options'))+" ")]},proxy:true}],null,true),model:{value:(_vm.form.type_id),callback:function ($$v) {_vm.$set(_vm.form, "type_id", $$v)},expression:"form.type_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,4175378637)})],1):_vm._e(),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"region_id","name":_vm.$t('region'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('region')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.direction,"label":"name","options":_vm.regions,"reduce":function (item) { return item.id; },"placeholder":_vm.$t('select')},on:{"input":function($event){return _vm.inputChange('region_id')}},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('No options'))+" ")]},proxy:true}],null,true),model:{value:(_vm.form.region_id),callback:function ($$v) {_vm.$set(_vm.form, "region_id", $$v)},expression:"form.region_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),(_vm.cities.length)?_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"city_id","name":_vm.$t('city'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('city')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.direction,"label":"name","options":_vm.cities,"reduce":function (item) { return item.id; },"placeholder":_vm.$t('select')},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('No options'))+" ")]},proxy:true}],null,true),model:{value:(_vm.form.city_id),callback:function ($$v) {_vm.$set(_vm.form, "city_id", $$v)},expression:"form.city_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3541683407)})],1):_vm._e(),_c('b-col',{attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('instrument_number'),"vid":"instrument_number","rules":{
                       required:true,
                       numeric: true,
                     }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                     var errors = ref.errors;
return [_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t('instrument_number')))])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('instrument_number'),"autocomplete":"off","type":"number"},model:{value:(_vm.form.instrument_number),callback:function ($$v) {_vm.$set(_vm.form, "instrument_number", $$v)},expression:"form.instrument_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('land_number'),"vid":"land_number","rules":{
                        required:true,
                       numeric: true,
                     }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                     var errors = ref.errors;
return [_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t('land_number')))])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('land_number'),"autocomplete":"off","type":"number"},model:{value:(_vm.form.land_number),callback:function ($$v) {_vm.$set(_vm.form, "land_number", $$v)},expression:"form.land_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"status","name":_vm.$t('status'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                     var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('status')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.direction,"label":"name","options":_vm.status_list,"reduce":function (item) { return item.id; },"placeholder":_vm.$t('status')},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(_vm._s(_vm.$t('No options')))]},proxy:true}],null,true),model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12 text-right"}},[_c('LoadingButton'),_c('b-button',{attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" "+_vm._s(_vm.$t('labels.reset'))+" ")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }